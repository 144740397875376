import React from 'react'
import { graphql } from 'gatsby'
import Pagina from '../components/Pagina'
import { getImage } from 'gatsby-plugin-image'
import grafismo from '../images/Home/Grafismo.svg'
import BackgroundImg from 'gatsby-background-image'
import { convertToBgImage } from 'gbimage-bridge'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'
import './styles/history.scss'
import Banner from '../components/Banner/index.js'

const SectionNossaHistoria = () => {
  // const intl = useIntl()
  return (
    <div className="container-fluid m-0 p-0" id='SectionNossaHistoria'>
      <div className="position-relative">
        <div className="container">
          <div className='floatLineGreen'></div>
          <div className="row py-lg-5 p-0 m-0 ">
            <div className="col-lg-7 text-gray lineGreen p-0">
              <p className="mb-4">Falar sobre a história do Grupo Lwart é mais do que contar a jornada do nascimento e da evolução de uma empresa. É narrar capítulos da materialização do desejo de empreender, contribuir para a história de sucesso das pessoas e colaborar com a construção de um mundo mais sustentável.
              </p>
              <p className='mb-4'>Conheça, agora, alguns dos momentos mais marcantes dessa jornada pautada pela busca de constante evolução e de compromisso com a governança.
              </p>
            </div>
            <div className="col-5 position-absolute p-0 grafismoDiv d-lg-block d-none">
              <img src={grafismo} alt="Icone Grafismo" width='650px' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const SectionTimeLine = ({ data }) => {
  const a = getImage(data.backgroundTimeline.childImageSharp.gatsbyImageData)
  const bgImage = convertToBgImage(a)
  return (
    <BackgroundImg
      {...bgImage}
      Tag="div"
    >
      <div className="container h-100 w-100 d-flex justify-content-center">
        <div className="row h-100 w-100 mt-5">
          <div className='d-flex align-items-center col-12 mt-5 mb-3'>
            <h2 className="pl-lg-5 pl-0 pb-3 text-green">Conheça a nossa história</h2>
          </div>
          <div className='timeLine col-12 d-lg-block d-none'>
            <div className='decadas'>
              <div className=''><p className='font-Spinnaker'>Década de 50</p></div>
              <div className=' customMargin'><p className='font-Spinnaker '>Década de 60</p></div>
              <div className=' customMargin'><p className='font-Spinnaker '>1975</p></div>
              <div className=' customMargin'><p className='font-Spinnaker '>1986</p></div>
              <div className=' customMargin'><p className='font-Spinnaker '>1997</p></div>
              <div className=' customMargin'><p className='font-Spinnaker '>2005</p></div>
              <div className=' customMargin'><p className='font-Spinnaker '>2005</p></div>
              <div className=' customMargin'><p className='font-Spinnaker '>2012</p></div>
              <div className=' customMargin'><p className='font-Spinnaker '>2014</p></div>
              <div className=' customMargin'><p className='font-Spinnaker '>2018</p></div>
              <div className=' customMargin'><p className='font-Spinnaker pt-2'>2020</p></div>
              <div className=''><p className='font-Spinnaker '>2023</p></div>
            </div>
            <div className='bolinhas'>
              <div className='circle-around'>
                <div className='circle'>
                  <div className='borderAround'></div>
                </div>
              </div>
              <div className='circle-around customMargin'>
                <div className='circle'>
                  <div className='borderAround'></div>
                </div>
              </div>
              <div className='circle-around customMargin'>
                <div className='circle'>
                  <div className='borderAround'></div>
                </div>
              </div>
              <div className='circle-around customMargin'>
                <div className='circle'>
                  <div className='borderAround'></div>
                </div>
              </div>
              <div className='circle-around customMargin'>
                <div className='circle'>
                  <div className='borderAround'></div>
                </div>
              </div>
              <div className='circle-around customMargin'>
                <div className='circle'>
                  <div className='borderAround'></div>
                </div>
              </div>
              <div className='circle-around customMargin'>
                <div className='circle'>
                  <div className='borderAround'></div>
                </div>
              </div>
              <div className='circle-around customMargin'>
                <div className='circle'>
                  <div className='borderAround'></div>
                </div>
              </div>
              <div className='circle-around customMargin'>
                <div className='circle'>
                  <div className='borderAround'></div>
                </div>
              </div>
              <div className='circle-around customMargin'>
                <div className='circle'>
                  <div className='borderAround'></div>
                </div>
              </div>
              <div style={{ marginTop: '60px' }}>
                <div className='circle-around customMargin'>
                  <div className='circle'>
                    <div className='borderAround'></div>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='circle-around'>
                  <div className='circle'>
                    <div className='borderAround'></div>
                  </div>
                </div>
              </div>

            </div>
            <div className='description'>
              <p>Os irmãos Luis, Alberto, Renato e Wilson Trecenti iniciam seus negócios em Lençóis Paulista/SP, com a
                fabricação de portas, janelas, tanques e equipamentos para usinas de açúcar e álcool.
              </p>
              <p>O crescimento dos negócios trouxe oportunidades de produzir também equipamentos agrícolas e,
                mais tarde, vergalhões, estruturas metálicas e até brinquedos para parques de diversão.</p>
              <p>Os Trecenti fundam a Lwart Lubrificantes, entrando no segmento de coleta e rerrefino de
                óleo lubrificante usado, empresa que deu origem ao Grupo Lwart.
              </p>
              <p>O grupo expande seus negócios e surge a Lwarcel Celulose, empresa que o longo de anos de investimento
                atingiu capacidade de produção de 250 mil toneladas de celulose de eucalipto ao ano para abastecer o
                mercado nacional e internacional de papéis e embalagens</p>
              <p>Inauguração da Lwart Química, especializada na produção de impermeabilizantes<br></br>
                para a construção civil</p>
              <p>Período de grande expansão da Lwarcel, atingindo a capacidade de 250 mil toneladas anuais<br></br>
                de celulose de eucalipto.
              </p>
              <p className=''>A Lwart Química adquire a marca Ciplak e também torna-se distribuidora exclusiva no País dos
                produtos Kryton, produtos de alta tecnologia para impermeabilização de concreto.</p>
              <p>Início de operação da nova fábrica da Lwart Lubrificantes, com capacidade de processar 200
                milhões de litros de óleo usado por ano e única produtora de óleo básico Grupo II na América Latina</p>
              <p className='pt-2'>Venda da Lwart Química para multinacional suíça Sika, impulsionando o crescimento e
                desenvolvimento das operações no Brasil.</p>
              <p>Venda da Lwarcel Celulose, referência em qualidade, gestão ambiental e eficiência energética, para o Grupo RGE,
                de Cingapura. O projeto de expansão da Lwarcel, planejado pelo Grupo, foi implementado e expandido, resultando
                no maior investimento privado no estado de São Paulo dos últimos 20 anos.
              </p>
              <p>A Lwart Lubrificantes lança seu novo posicionamento e se transforma em Lwart Soluções Ambientais. Entra em operação
                a área de Gestão de Resíduos para coletar, destinar e transformar resíduos pós-consumo nos mais diversos segmentos.</p>
              <p>O Grupo Lwart adquire a VitalForce, empresa inovadora do segmento de fertilizantes e defensivos agrícolas. Assim, segue evoluindo sua vocação empreendedora em busca constante por novos negócios.</p>
            </div>
          </div>
          <div className='timeLineMobile col-12 d-lg-none row'>
            <div className='position-relative col-12'>
              <div className='bolinhas'>
                <div className='circle-around'>
                  <div className='circle'>
                    <div className='borderAround'></div>
                  </div>
                </div>
              </div>
              <div className='descriptionMobile'>
                <h2 className='text-white'>Década de 50</h2>
                <p>Os irmãos Luis, Alberto, Renato e Wilson Trecenti iniciam seus negócios em Lençóis Paulista/SP, com a
                  fabricação de portas, janelas, tanques e equipamentos para usinas de açúcar e álcool.
                </p>
              </div>
            </div>
            <div className='position-relative col-12'>
              <div className='bolinhas' >
                <div className='circle-around'>
                  <div className='circle'>
                    <div className='borderAround'></div>
                  </div>
                </div>
              </div>
              <div className='descriptionMobile'>
                <h2 className='text-white'>Década de 60</h2>
                <p>O crescimento dos negócios trouxe oportunidades de produzir também equipamentos agrícolas e, mais tarde, vergalhões, estruturas metálicas e até brinquedos para parques de diversão.
                </p>
              </div>
            </div>
            <div className='col-12'>
              <div className='bolinhas' >
                <div className='circle-around'>
                  <div className='circle'>
                    <div className='borderAround'></div>
                  </div>
                </div>
              </div>
              <div className='descriptionMobile'>
                <h2 className='text-white'>1975</h2>
                <p>Os Trecenti fundam a Lwart Lubrificantes, entrando no segmento de coleta e rerrefino de óleo lubrificante usado, empresa que deu origem ao Grupo Lwart.
                </p>
              </div>
            </div>
            <div className='col-12'>
              <div className='bolinhas' >
                <div className='circle-around'>
                  <div className='circle'>
                    <div className='borderAround'></div>
                  </div>
                </div>
              </div>
              <div className='descriptionMobile'>
                <h2 className='text-white'>1986</h2>
                <p>O grupo expande seus negócios e surge a Lwarcel Celulose, empresa que o longo de anos de investimento atingiu capacidade de produção de 250 mil toneladas de celulose de eucalipto ao ano para abastecer o mercado nacional e internacional de papéis e embalagens
                </p>
              </div>
            </div>
            <div className='col-12'>
              <div className='bolinhas' >
                <div className='circle-around'>
                  <div className='circle'>
                    <div className='borderAround'></div>
                  </div>
                </div>
              </div>
              <div className='descriptionMobile'>
                <h2 className='text-white'>1997</h2>
                <p>Inauguração da Lwart Química, especializada na produção de impermeabilizantes
                  para a construção civil.

                </p>
              </div>
            </div>
            <div className='col-12'>
              <div className='bolinhas' >
                <div className='circle-around'>
                  <div className='circle'>
                    <div className='borderAround'></div>
                  </div>
                </div>
              </div>
              <div className='descriptionMobile'>
                <h2 className='text-white'>2005</h2>
                <p>Período de grande expansão da Lwarcel, atingindo a capacidade de 250 mil toneladas anuais
                  de celulose de eucalipto
                </p>
              </div>
            </div>
            <div className='col-12'>
              <div className='bolinhas' >
                <div className='circle-around'>
                  <div className='circle'>
                    <div className='borderAround'></div>
                  </div>
                </div>
              </div>
              <div className='descriptionMobile'>
                <h2 className='text-white'>2005</h2>
                <p>A Lwart Química adquire a marca Ciplak e também torna-se distribuidora exclusiva no País dos
                  produtos Kryton, produtos de alta tecnologia para impermeabilização de concreto.
                </p>
              </div>
            </div>
            <div className='col-12'>
              <div className='bolinhas' >
                <div className='circle-around'>
                  <div className='circle'>
                    <div className='borderAround'></div>
                  </div>
                </div>
              </div>
              <div className='descriptionMobile'>
                <h2 className='text-white'>2012</h2>
                <p>Início de operação da nova fábrica da Lwart Lubrificantes, com capacidade de processar 200
                  milhões de litros de óleo usado por ano e única produtora de óleo básico Grupo II na América Latina.
                </p>
              </div>
            </div>
            <div className='col-12'>
              <div className='bolinhas' >
                <div className='circle-around'>
                  <div className='circle'>
                    <div className='borderAround'></div>
                  </div>
                </div>
              </div>
              <div className='descriptionMobile'>
                <h2 className='text-white'>2014</h2>
                <p>Venda da Lwart Química para multinacional suíça Sika, impulsionando o crescimento e
                  desenvolvimento das operações no Brasil
                </p>
              </div>
            </div>
            <div className='col-12'>
              <div className='bolinhas' >
                <div className='circle-around'>
                  <div className='circle'>
                    <div className='borderAround'></div>
                  </div>
                </div>
              </div>
              <div className='descriptionMobile'>
                <h2 className='text-white'>2018</h2>
                <p>Venda da Lwarcel Celulose, referência em qualidade, gestão ambiental e eficiência energética, para o Grupo RGE,
                  de Cingapura. O projeto de expansão da Lwarcel, planejado pelo Grupo, foi implementado e expandido, resultando
                  no maior investimento privado no estado de São Paulo dos últimos 20 anos
                </p>
              </div>
            </div>
            <div className='col-12'>
              <div className='bolinhas' >
                <div className='circle-around'>
                  <div className='circle'>
                    <div className='borderAround'></div>
                  </div>
                </div>
              </div>
              <div className='descriptionMobile'>
                <h2 className='text-white'>2020</h2>
                <p>A Lwart Lubrificantes lança seu novo posicionamento e se transforma em Lwart Soluções Ambientais. Entra em operação
                  a área de Gestão de Resíduos para coletar, destinar e transformar resíduos pós-consumo nos mais diversos segmentos.
                </p>
              </div>
            </div>
            <div className='col-12'>
              <div className='bolinhas' >
                <div className='circle-around'>
                  <div className='circle'>
                    <div className='borderAround'></div>
                  </div>
                </div>
              </div>
              <div className='descriptionMobile'>
                <h2 className='text-white'>2023</h2>
                <p>O Grupo Lwart adquire a VitalForce, empresa inovadora do segmento de fertilizantes e defensivos agrícolas. Assim, segue evoluindo sua vocação empreendedora em busca constante por novos negócios.
                </p>
              </div>
            </div>
          </div>
          {/* <div className='offset-lg-2 col-lg-10 col-12 mb-4'>
            <p className='pl-3 text-white font-size-14'>*A transação está sujeita às condições habituais de fechamento e aprovações regulatórias, inclusive a aprovação concorrencial do Conselho Administrativo de Defesa Econômica – CADE, e deve ser concluída nos próximos meses, após a obtenção das devidas aprovações.
            </p>
          </div> */}

        </div>

      </div>
    </BackgroundImg>
  )
}

const Historia = ({ data, pageContext }) => {
  const intl = useIntl()
  return (
    <div>
      <Pagina pagina={data.pagina} />
      <Banner title={parse(intl.formatMessage({ id: 'historia.banner.titulo' }))} banner={data.bannerDesktop.childImageSharp.gatsbyImageData} alt='Banner History' />
      <SectionNossaHistoria />
      <SectionTimeLine data={data} />
    </div>
  )
}

export default Historia

export const historyQuery = graphql`
  query historyQuery($locale: String){
    pagina: contentfulPagina(path: {eq: "/historia/"}, node_locale: {regex: $locale}) {
      contentful_id
      node_locale
      metaTitle
      metaDescription
      pageSummary
      pageTitle
      path
      tags
    }
    bannerDesktop: file(relativePath: {eq: "History/BannerHistory.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    backgroundTimeline: file(relativePath: {eq: "History/bgtimeline.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    imagemCollapse1: file(relativePath: {eq: "History/bgCollapse1.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    imagemCollapse2: file(relativePath: {eq: "History/bgCollapse2.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }`
